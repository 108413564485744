<template>
    <div class="addressInfo">
        <el-form class="search" style="display: flex !important">
            <el-form-item  label-width="90px" label="订单号">
                <el-input v-model:value="order_id"/>
            </el-form-item>&nbsp;&nbsp;&nbsp;
            <el-button type="primary" icon="el-icon-search" @click="getTableData(1)">搜索</el-button>&nbsp;&nbsp;&nbsp;
            <el-button type="" icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form>
        <div class="infoBox">
            <el-button type="primary" @click="settlement">一键结算</el-button>
            <div class="table-data">
                <TableLoading :loadShow="loadShow"></TableLoading>
                <el-table :data="tableData" border>
                    <el-table-column fixed prop="index" label="序号" width="70"></el-table-column>
                    <el-table-column prop="order_id" label="订单号" width="90" />
                    <el-table-column prop="real_price" label="订单收款"/>
                    <el-table-column prop="money" label="所得金额"/>
                    <el-table-column prop="percentage" label="占比"/>
                    <el-table-column prop="order_type" label="订单类型">
                        <template slot-scope="{ row }">
                                <el-tag>{{row.order_type==1?'自销订单':'外部订单'}}</el-tag>
                            </template>
                    </el-table-column>
                    <el-table-column prop="status" label="结算状态">
                        <template slot-scope="{ row }">
                            <el-tag :type="row.status==1?'':row.status==2?'success':'error'">{{row.status==1?'待结算':row.status==2?'结算成功':'结算失败'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="err_msg" label="失败原因" ></el-table-column>
                </el-table>
            </div>
            <div class="pageBox">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-sizes="[10]" :page-size="pageSize" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { GetOrderSettledInfo, GoSettlementApi } from "@/common/api";
import TableLoading from "@/components/index/TableLoading.vue";
import StatusTab from "@/components/index/StatusTab.vue";

export default {
    data() {
        return {
            loadShow: false,
            pageNum: 1, // 当前页数
            total: 0, // 总数据量
            pageSize: 10, // 每页显示条数
            tableData: [],
            order_id: '',   //批次号
        };
    },
    components: {
        TableLoading,
        StatusTab,
    },
    mounted() {
        this.getTableData(1)
    },
    methods: {
        // 重置
        reset() {
            this.order_id = "";
            this.pageNum = 1;
            this.getTableData();
        },
        
        // 每页条数改变时
        handleSizeChange(val) {
            this.pageSize = val;
        },
        // 当前页改变时
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getTableData();
        },
        // 获取表格数据
        async getTableData(page) {
            if (page) this.pageNum = page;
            this.loadShow = true;
            let tableData = await GetOrderSettledInfo({
                order_id: this.order_id,
                page: this.pageNum,
            });
            this.loadShow = false;
            if (!tableData) return;
            tableData.data.forEach((element, index) => {
                element.index = index + 1 + (this.pageNum - 1) * 10;
            });
            this.tableData = tableData.data;
            this.total = tableData.total;
        },
        async settlement(){
            let res = await GoSettlementApi()
            if(!res) return
            this.$router.push('/admin/SettledBatchInfo')
        }
    },
};
</script>

<style lang="less" scoped>

</style>
